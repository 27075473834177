import { Event } from './interfaces/event.interface';
import { EventSearch } from './interfaces/event-search.interface';

export enum TypeModalEvent {
  Create,
  Edit,
  Look,
  Close,
}

export class GetAuthLink {
  static readonly type = '[Calendar] Get auth link';
}

export class GetToken {
  static readonly type = '[Calendar] Get token';

  constructor(public code: string) {}
}

export class GetTokenSuccess {
  static readonly type = '[Calendar] Get token success';
}

export class GetEvents {
  static readonly type = '[Calendar] Get events';

  constructor(
    public params: EventSearch,
    public uid: string,
  ) {}
}

export class CreateEvent {
  static readonly type = '[Calendar] Create event';

  constructor(
    public event: Event,
    public uid: string,
  ) {}
}

export class SetSelectedEvent {
  static readonly type = '[Calendar] Set selected event';

  constructor(
    public event: Event,
    public type = '',
  ) {}
}

export class GetEventById {
  static readonly type = '[Calendar] Get event by id';

  constructor(
    public id: string,
    public uid: string,
  ) {}
}

export class EditEvent {
  static readonly type = '[Calendar] Edit event';

  constructor(
    public id: string,
    public event: Event,
    public uid: string,
  ) {}
}

export class DeleteEvent {
  static readonly type = '[Calendar] Delete event';

  constructor(
    public id: string,
    public uid: string,
  ) {}
}

export class ModalEvent {
  static readonly type = '[Calendar] Modal event';

  constructor(
    public stateModal: TypeModalEvent,
    public id: string = null,
    public userUid?: string,
  ) {}
}

export class SetEventSuccess {
  static readonly type = '[Calendar] Set event success';
}

export class ClearEvents {
  static readonly type = '[EmployeeCalendar] Clear events';
}

export class ClearSetSelectedEvent {
  static readonly type = '[EmployeeCalendar] ClearSetSelectedEvent';
}

export class GetPortalUsers {
  static readonly type = '[Calendar] Get portal users';

  constructor(public search: string) {}
}

export class GetUserCalendarPage {
  static readonly type = '[Calendar] Get user calendar';

  constructor(
    public params: EventSearch,
    public uid: string,
  ) {}
}

export class CreateEventCalendarPage {
  static readonly type = '[Calendar] Create user calendar';

  constructor(
    public params: Event,
    public uid: string,
  ) {}
}

export class SaveEventCalendar {
  static readonly type = '[Calendar] Save user calendar';

  constructor(
    public params: Event,
    public idEvent: string,
    public uid: string,
  ) {}
}

export class DeleteEventCalendar {
  static readonly type = '[Calendar] Delete user calendar';

  constructor(
    public idEvent: string,
    public uid: string = '',
  ) {}
}

export class AddUserCalendar {
  static readonly type = '[Calendar] add user calendar';

  constructor(
    public params: EventSearch,
    public uid: string,
  ) {}
}

export class RemoveUserCalendarPage {
  static readonly type = '[Calendar] remove user calendar';

  constructor(public uid: string) {}
}

export class GetGroupOfUsersCalendarPage {
  static readonly type = '[Calendar] Get users calendar';

  constructor(
    public params: EventSearch,
    public users: any[],
  ) {}
}

export class GetEmployeeForCalendar {
  static readonly type = '[Calendar] Get employee for calendar';

  constructor(public search: string) {}
}

export class GetPatientForCalendar {
  static readonly type = '[Calendar] Get patient for calendar';

  constructor(public search: string) {}
}
